/*
* Author: LJH
* Date: 2021/12/31
* Description:
*/
import Axios from 'axios';
import loadingController from './loading-controller';
import {to} from './utils';
import Toast from '../components/Toast/index.js';
import httpStatusMsg from '../config/errorMsg/httpStatus';

function httpStatusHandler(intl, status) {
  const message = httpStatusMsg[status] || status;
  Toast(message);
}

function abnormalErrorHandler(intl, error, url) {
  const messageLangMap = {
    'zh-cn': 'messageSC',
    'zh-tw': 'messageTC',
    'en': 'messageEN'
  };
  const { status } = error;
  let message = '';
  if(status) {
    if (status.ecode) {
      message = intl.formatMessage({id: `code.codeMsg.${status.ecode}`});
    }
    if(!message || /^code\./.test(message)) {
      const languageMatch = /^\/([^/]+)\//.exec(location.pathname);
      message = status[messageLangMap[languageMatch?.[1]]] || status.message;
    }
  }
  Toast(message || 'Unknown Error');
}
function ajax({
                method,
                url,
                data = {},
                params = {},
                loadingArea = 'global',
                actionArea = 'global',
                loadingType = 'loading1',
                loadingSize = 10,
                customErrorHandler = () => true,
                timeout = 15000,
                headers = {},
                download = false,
                canRefreshToken = true,
                // 多语言获取接口
                intl = { formatMessage: ()=>false }
              }) {
  let abort = null;
  const cancelToken = new Axios.CancelToken((c) => {
    abort = c;
  });
  const promise = new Promise(async (resolve, reject) => {
    // 必传参数处理
    const defaultParams = {  };
    params = { ...params, ...defaultParams };



    // loading 处理
    loadingController.add(loadingArea, {
      type: loadingType,
      size: loadingSize,
    });
    let [err, result] = await to(
      Axios({
        url,
        method,
        params: method.toUpperCase() !== 'GET' ? undefined : params,
        data: method.toUpperCase() == 'GET' ? undefined : data,
        timeout,
        cancelToken,
        headers: { common: { ...headers } },
      })
    );
    // loading 处理
    loadingController.sub(loadingArea, { type: loadingType });
    // 网络错误（返回网络status非200）
    if (err) {
      // abort
      if (err instanceof Axios.Cancel) {
        reject(err);
      }
      // timeout,
      if (
        (err.code && err.code === 'ECONNABORTED') ||
        (err.stack && err.stack.indexOf('Network Error') > -1)
      ) {
        const message = 'Network Error: Timeout.Please try again!';
        Toast(message);
        reject(err);
      }
      // 401 ~ 502
      if (err.response && err.response.status) {
        // refresh token
        /*
        if (err.response.status === 401 && canRefreshToken) {
          const [, refresh] = await to(RefreshToken());
          if (refresh) {
            const args = Array.prototype.slice.call(arguments);
            args[0].canRefreshToken = false;
            [err, result] = await to(ajax(...args));
            if (result) return resolve(result);
          }
        }
        */
        httpStatusHandler(intl, err.response.status);
        reject(err);
      }
    }
    else {
      const {data} = result;
      if (data !== undefined) {
        // 下载成功则返回字节，有错误则会有统一的返回结构
        if (download && !data.status) {
          return resolve(data);
        }
        if (data.status?.ecode === 0) {
          return resolve([undefined, null].includes(data.data) ? {} : data.data);
        }
        const needHandleError = await customErrorHandler(data, err);
        // 集中错误处理
        if (needHandleError) {
          abnormalErrorHandler(intl, data || err, url);
        }
        return reject(data);
      }

      console.log(`%c${url}请求成功，但无数据返回`, 'color: blue');
      reject('The request was successful but the server did not return data');
    }
  });
  promise.abort = abort;
  promise
  .catch((e) => console.log(e));
  return promise;
}

export default ajax;
