/*
* Author: LJH
* Date: 2021/1/26
* Description: if else else-if 判断
*/
import React from 'react';

export default function Judge(props) {
    const children = props.children instanceof Array ? props.children : [props.children]
    const child = children.find(it => {
        if (it.props.hasOwnProperty('if') && it.props.if) {
            return true;
        }
        else if (it.props.hasOwnProperty('else-if') && it.props['else-if']) {
            return true;
        }
        else if (it.props.hasOwnProperty('else')) return true;
        return false;
    })
    return (
        <>{child}</>
    )
}
