/*
* Author: LJH
* Date: 2021/12/29
* Description:
*/

let width = null;
export default function () {
  if(width === null) {
    const outer = document.createElement('div');
    outer.style.padding = 0;
    outer.style.paddingLeft = 0;
    outer.style.paddingRight = 0;
    outer.style.visibility = 'hidden';
    outer.style.width = '100px';
    outer.style.height = '100px';
    outer.style.position = 'absolute';
    outer.style.top = '-9999px';
    document.body.appendChild(outer);
    const widthNoScroll = outer.offsetWidth;
    outer.style.overflow = 'scroll';
    const inner = document.createElement('div');
    inner.style.width = '100%';
    outer.appendChild(inner);
    const widthHasScroll = inner.offsetWidth;
    outer.parentNode.removeChild(outer);
    width = widthNoScroll - widthHasScroll;
  }
  return width;
}
