/*
 * Author: LJH
 * Date: 2021/2/19
 * Description:
 */

/* eslint-disable no-param-reassign, no-unused-expressions */
import { createElement as h, debounce } from './utils';

const loadingAutoCloseTime = 30 * 1000;

function getDom(area) {
  return new Promise((resolve) => {
    let dom = area;
    if (area === 'global') {
      dom = document.body;
      resolve(dom);
    }
    else if (typeof area === 'string') {
      dom = document.querySelector(area);
      if (!dom) {
        const timeout = setTimeout(() => {
          dom = document.querySelector(area);
          return resolve(dom);
        })
        setTimeout(() => {
          dom = document.querySelector(area);
          if (dom) {
            clearTimeout(timeout);
            return resolve(dom);
          }
        }, 0);
      }
      else resolve(dom);
    }
    else resolve(dom);
  });
}

function createLoading2Dom(className = '') {
  return h(
    'div',
    { className: `_loading_wrap _loadingController hide ${className} ant-spin-dot` },
    [
      h('div', { className: 'ant-spin-dot-spin toggleSize' }, [
        h('span', { className: 'ant-spin-dot-item' }),
        h('span', { className: 'ant-spin-dot-item' }),
        h('span', { className: 'ant-spin-dot-item' }),
        h('span', { className: 'ant-spin-dot-item' }),
      ])
    ]
  );
}

function createLoading1Dom(className = '') {
  return h(
    'div',
    { className: `_loading_wrap _loadingController hide ${className}` },
    [
      h('div', { className: '_mask' }),
      h('div', { className: '_loading center' }, [
        h('div', { className: '_loading-content begin' }),
        h('div', { className: '_loading-content-wrap' }),
      ]),
    ]
  );
}

function clearLoading(dom, type) {
  dom.loadingNum = 0;
  switch (type) {
    case 'loading1': {
      const loadingDom = dom.loadingDom;
      if (loadingDom) {
        const classList = loadingDom.classList;
        classList.add('hide');
      }
      dom.clearLoadingTimeout = setTimeout(() => {
        /*if (loadingDom && loadingDom.parentNode) {
          loadingDom.parentNode.removeChild(loadingDom);
        }*/
        dom.clearLoadingTimeout = null;
      }, 500);
      break;
    }
    case 'loading2': {
      const loadingDom = dom.loadingDom;
      if (loadingDom) {
        const classList = loadingDom.classList;
        classList.add('hide');
      }
      dom.clearLoadingTimeout = setTimeout(() => {
        if (loadingDom && loadingDom.parentNode) {
          loadingDom.parentNode.removeChild(loadingDom);
        }
        dom.clearLoadingTimeout = null;
      }, 500);
      break;
    }
    case 'el-button': {
      dom.loading = false;
      break;
    }
    default: {
      break;
    }
  }
}

const controller = {
  async add(area, { num = 1, type = 'loading1' }) {
    const dom = await getDom(area);
    console.log(area, dom)
    if (!dom) return false;
    if (!dom.loadingNum) {
      switch (type) {
        case 'loading1': {
          let loadingDom = dom.loadingDom;
          if (!loadingDom) {
            const position = window.getComputedStyle(dom).getPropertyValue('position');
            if (!['relative', 'fixed', 'absolute', 'sticky'].includes(position)) {
              dom.style.position = 'relative';
            }
            loadingDom = createLoading1Dom();
            dom.loadingDom = loadingDom;
            dom.appendChild(loadingDom);
          }
          // 准备删除dom的timeout
          if (dom.clearLoadingTimeout) {
            clearTimeout(dom.clearLoadingTimeout);
            dom.clearLoadingTimeout = null;
          }
          const classList = loadingDom.classList;
          if (classList.contains('hide')) {
            setTimeout(() => {
              classList.remove('hide');
            }, 0);
          }
          break;
        }
        case 'loading2': {
          let loadingDom = dom.loadingDom;
          if (!loadingDom) {
            const position = window.getComputedStyle(dom).getPropertyValue('position');
            if (!['relative', 'fixed', 'absolute', 'sticky'].includes(position)) {
              dom.style.position = 'relative';
            }
            loadingDom = createLoading2Dom(area === 'global' ? 'zindex-9999' : '');
            dom.loadingDom = loadingDom;
          }
          // 准备删除dom的timeout
          if (dom.clearLoadingTimeout) {
            clearTimeout(dom.clearLoadingTimeout);
            dom.clearLoadingTimeout = null;
          }
          else {
            dom.appendChild(loadingDom);
          }
          const classList = loadingDom.classList;
          if (classList.contains('hide')) {
            setTimeout(() => {
              classList.remove('hide');
            }, 0);
          }
          break;
        }
        case 'el-button': {
          dom.loading = true;
          break;
        }
        default: {
          break;
        }
      }
    }
    dom.loadingNum = (dom.loadingNum || 0) + num;
    if (!dom.deboundClearLoading) {
      dom.deboundClearLoading = debounce(() => clearLoading(dom, type), loadingAutoCloseTime);
    }
    dom.deboundClearLoading();
  },
  async sub(area, { num = 1, type = 'loading1' }) {
    const dom = await getDom(area);
    if (!dom) return false;
    dom.loadingNum -= num;
    if (dom.loadingNum === 0) {
      clearLoading(dom, type);
      dom.deboundClearLoading &&
        dom.deboundClearLoading.abort &&
        dom.deboundClearLoading.abort();
    }
    if (dom.loadingNum > 0) {
      dom.deboundClearLoading && dom.deboundClearLoading();
    }
    dom.loadingNum = dom.loadingNum >= 0 ? dom.loadingNum : 0;
  },
  async change(area, { num, type = 'loading1' }) {
    const dom = await getDom(area);
    if (!dom) return false;
    if (!num) {
      clearLoading(dom, type);
      dom.deboundClearLoading &&
        dom.deboundClearLoading.abort &&
        dom.deboundClearLoading.abort();
    }
    dom.loadingNum = num;
  },
};

export default controller;
function to(promise) {
  if (promise && promise.then) {
    return promise.then(data => [null, data]).catch(err => [err])
  }
  return [null, promise]
}

function* ajax() {
  const a = yield Promise.reject('err');
  debugger
  const is = yield 'err';
  debugger
  if(is) console.warn('err');
  else console.log('err');
}

async function doit() {
  const g = ajax();
  const p = g.next();
  console.log(p)
  const [err] = await to(p.value || p);
  g.next('test');
  debugger
  if(err) g.next(true);
  else g.next(false);
}
async function doit2() {
  const g = ajax();
  const p = g.next();
  console.log(p)
  p.value
  .then(data => {
    g.next(false)
  })
  .catch(err => {
    g.next(true);
  })
}
