import ReactDOM from 'react-dom';
import Dialog from './index2'
import InitToast from "../Toast";
function Init(props, type) {
  return new Promise((resolve, reject)=>{
    const className = `_${type}-instance`;
    let div = document.querySelector(`.${className}`);
    if(!div) {
      div = document.createElement('div');
      div.className = className;
      div.id = `dialog${Math.random()}`;
      document.body.appendChild(div);
    }
    const close = function(type, action) {
      if(type)
        resolve(action)
      else {
        reject(action);
        setTimeout(() => {
          ReactDOM.unmountComponentAtNode(div);
        }, 500)
      }
    }

    ReactDOM.render(<Dialog {...props} id={div.id} close={close} confirm={confirm}></Dialog>, div);
  })

}

const dialogs = {
  alert({title, content, btnText, showClose, className}) {
    return Init({title, content, confirmBtnText: btnText, cancelBtnText: false, showClose, className}, 'alert');
  },
  confirm({title, content, confirmBtnText, cancelBtnText, showClose, className}) {
    return Init({title, content, confirmBtnText, cancelBtnText, showClose, className}, 'confirm');
  },
  prompt({title, label, content, confirmBtnText, cancelBtnText, showClose, className, inputConfig, callback}) {
    return Init({title, label, content, cancelBtnText, confirmBtnText, showClose, className, inputConfig, input: true, callback}, 'prompt');
  }
}
export default dialogs
