/*
* Author: LJH
* Date: 2021/12/29
* Description:
*/

import {css} from "@emotion/core";
import {Desktop} from "../../constants/responsive";

const InputCss = {
  wrap: css`
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 25px;
    position: relative;
    width: 100%;
    vertical-align: middle;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    ${Desktop} {
      height: 50px;
    }
    ${Desktop} {
      height: 50px;
    }
    `,
  input: css`
    line-height: 40px;
    background-color: transparent;
    flex: 1 1;
    padding: 0 20px;
    font-size: 12px;
    &::placeholder {
      color: #808080;
      font-size: 12px;
    }
    ${Desktop} {
      font-size: 18px;
      line-height: 50px;
      &::placeholder {
        font-size: 18px;
      }
    }
    ${Desktop} {
      font-size: 18px;
      line-height: 50px;
      &::placeholder {
        font-size: 18px;
      }
    }
  `,
}

const Input = (props) => {
  const {
    children,
    inputWrapCss,
    inputCss,
    inputStyle,
    inputClass,
    name,
    ...others
  } = props;
  const slots = {};
  let childs = typeof children === 'object' ? [children] : children;
  (childs||[]).map(it=>{
    slots[it.key] = it;
  })
  return (
    <div css={css`${InputCss.wrap};${inputWrapCss}`}>
      {slots.prefix}
      <input css={css`${InputCss.input};${inputCss}`} style={inputStyle} className={inputClass} name={name} {...others} />
      {slots.suffix}
    </div>
  )
}

export default Input
