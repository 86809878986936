/*
* Author: LJH
* Date: 2022/1/7
* Description:
*/

import React from 'react';
import Transition from '../Transition';
import './index.scoped.scss';

function Popup(props) {
  return (
    <Transition name="popup" duration={props.duration || 500} show={props.show} className={`popup-wrap ${props.className}`} delayMount={true}>
        {/* props.close 可加promise 用于判断 */}
        <div className="mask" onClick={props.changeShow}></div>
        <div className="popup bottom">
          {props.children}
        </div>
    </Transition>
  )
}

export default Popup;
