/*
* Author: LJH
* Date: 2021/12/24
* Description: 月份对应的多语言key， 使用时请用这个key映射成多语言
* exp: <FormattedHTMLMessage id={monthMap[1]}></FormattedHTMLMessage>,
*       intl.formatMessage({id: monthMap[1]})
*/

const map = {
  1: 'v5.appointment.January_text',
  2: 'v5.appointment.February_text',
  3: 'v5.appointment.Mar_text',
  4: 'v5.appointment.April_text',
  5: 'v5.appointment.May_text',
  6: 'v5.appointment.June_text',
  7: 'v5.appointment.July_text',
  8: 'v5.appointment.August_text',
  9: 'v5.appointment.September_text',
  10: 'v5.appointment.October_text',
  11: 'v5.appointment.November_text',
  12: 'v5.appointment.December_text'
}
export default map;
