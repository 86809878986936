/*
* Author: LJH
* Date: 2021/12/29
* Description:
*/

import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import Group from './group';
import './index.scss';

function Select(props, ref) {
  const {
    data,
    columns
  } = props;
  const selectEl = useRef(null);
  function getValue() {
    const values = refs.map(it => it.current.getValue());
    return values.length == 1 ? values[0] : values;
  }
  function change() {
    const value = getValue();
    props.onChange && props.onChange(value);
  }
  useImperativeHandle(ref, () => ({
    getValue: () => getValue(),
  }));
  const refs = [];
  const childs = (columns || [{}]).map((it={}, $i) => {
    refs[$i] = useRef(null);
    return <Group
      key={$i}
      data={(data||[])[$i]}
      optionText={it.text}
      optionValue={it.value}
      optionsRender={it.render}
      ref={refs[$i]}
      onChange={change}
    />
  })
  return (
    <div className="_select" style={props.style} ref={selectEl}>
      {childs}
    </div>
  )
}

export default forwardRef(Select);
