/*
* Author: LJH
* Date: 2021/12/31
* Description:
*   props:
*     transitionName:
*     show:
*     changeShow()
*     actionDom
*     activeByDom
*     maxHeight
*/


import React, {useRef, useState, useEffect} from 'react';
import {useMount} from 'react-use';
import Transition from '../Transition';
import './index.scss';

function computedStyle(el, dom) {
    const arrowSize = 4;
    const domRect = dom.getBoundingClientRect();
    const elRect = el.getBoundingClientRect();
    const elStyle = window.getComputedStyle(el);
    const elTransform = elStyle.transform || elStyle.webkitTransform || elStyle.OTransform || elStyle.MzTransform || elStyle.MozTransform;
    const scaleY = !elTransform || elTransform == 'none' ? 1 : /^matrix\([0-9.]+\s*,\s*[0-9.]+\s*,\s*[0-9.]+\s*,\s*([0-9.]+)\s*,/.exec(elTransform)[1];
    const offsetParent = el.offsetParent;
    const scrollTop = offsetParent ? (offsetParent === document.body ? Math.max(document.documentElement.scrollTop, document.body.scrollTop) : offsetParent.scrollTop) : 0;
    // 复原高度
    elRect.height /= scaleY;
    const style = {
      top: domRect.bottom + arrowSize * 2,
      left: domRect.left + domRect.width / 2 - elRect.width / 2,
    };
    const style2 = {};
    const screen = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    // 组件高度处理
    /*
    if (elRect.height >= this.maxHeight) {
      style.height = this.maxHeight;
      elRect.height = this.maxHeight;
    }
    */
    if (style.top + elRect.height > screen.height) {
      let top = domRect.top - elRect.height - arrowSize * 2;
      top = top < 0 ? 0 : top;
      style.top = top;
    }
    if (style.left < 0) {
      style.left = 10;
    }
    if (style.left + elRect.width > screen.width) {
      delete style.left;
      style.right = 10;
    }
    // arrow style compute
    if (style.top > domRect.top) {
      style2.top = 1 - arrowSize;
      style2.borderRightColor = 'transparent';
      style2.borderBottomColor = 'transparent';
      //this.position = 'bottom';
    }
    else {
      style2.bottom = 1 - arrowSize;
      style2.borderLeftColor = 'transparent';
      style2.borderTopColor = 'transparent';
      //this.position = 'top';
    }
    if (style.left !== undefined) {
      let left = domRect.left + domRect.width / 2 - arrowSize - style.left;
      left = left > elRect.width / 2 - arrowSize / 2 ? elRect.width / 2 - arrowSize / 2 : left;
      style2.left = left;
    }
    if (style.right !== undefined) {
      let right = screen.width - domRect.right + domRect.width / 2 - arrowSize / 2 - style.right;
      right = right > elRect.width / 2 - arrowSize / 2 ? elRect.width / 2 - arrowSize / 2 : right;
      style2.right = right;
    }
    // 边缘特殊处理 左
    if (style2.left && style2.left< 1) {
      style2.borderLeftColor = 'transparent';
      style2.left = 0 - arrowSize;
      if (style.top > domRect.top) style.borderTopLeftRadius = 0;
      else style.borderBottomLeftRadius = 0;
    }
    // 右
    if (style2.right && style2.right < 1) {
      style2.borderTopColor = 'transparent';
      style2.right = 0 - arrowSize;
      if (style.top > domRect.top) style.borderTopLeftRadius = 0;
      else style.borderBottomLeftRadius = 0;
    }
    style.width = style.width && `${style.width}px`;
    style.height = style.height && `${style.height}px`;
    style.top = style.top && `${style.top + scrollTop}px`;
    style.bottom = style.bottom && `${style.bottom + offsetParent.scrollHeight - offsetParent.offsetTop - screen.height}px`;
    style.left = style.left && `${style.left}px`;
    style.right = style.right && `${style.right}px`;

    style2.width = style2.width && `${style2.width}px`;
    style2.height = style2.height && `${style2.height}px`;
    style2.top = style2.top && `${style2.top}px`;
    style2.bottom = style2.bottom && `${style2.bottom}px`;
    style2.left = style2.left && `${style2.left}px`;
    style2.right = style2.right && `${style2.right}px`;
    return [
      style,
      style2
    ];
}
function actionDomCompute(dom) {
  if (typeof dom === 'string') dom = document.querySelector(dom);
  return dom;
}


function Popover(props) {
  const [show, setShow] = useState(false);
  const [wrapStype, setWrapStyle] = useState({});
  const wrapRef = useRef(null);
  function handleDocumentClick(e) {
    const actionDom = actionDomCompute(props.actionDom);
    const wrapEl= wrapRef.current;
    if (wrapEl && (!wrapEl.contains(e.target)) && (e.target !== actionDom || !actionDom.contains(e.target))) {
      props.changeShow && props.changeShow(false);
    }
  }
  useMount(() => {
    document.addEventListener('click', handleDocumentClick)
    // on(document, 'click', handleDocumentClick);
    // on(actionDomCompute(), 'click', domActiveHandle);
  });
  useEffect(() => {
    setShow(props.show);
    if(props.show) {
      setTimeout(() => {
        const [wstyle, astyle] = computedStyle(wrapRef.current, actionDomCompute(props.actionDom));
        setWrapStyle(wstyle);
      }, 0)
    }
  }, [props.show]);
  return (
    <Transition name={props.transitionName || "popover-transition"} duration="300" show={show} delayMount={true}>
      <div>
        <div className="popover" style={{...wrapStype, ...props.style}} ref={wrapRef}>
          <div className="popover-arrow"></div>
          <div className="popover-content">
            {props.children}
          </div>
        </div>
      </div>
    </Transition>
  )

}

export default Popover;
