/*
* Author: LJH
* Date: 2021/12/29
* Description:
*/

import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from 'react';
import getScrollBarWidth from '../../utils/scrollBarWidth';
import { isSupportCss } from '../../utils/utils';
import { css } from '@emotion/core';
import './group.scss';
function SelectGroup(props, ref) {
  const {
    data,
    optionText,
    optionValue,
    optionsRender,
  } = props;
  const [scrollBarWidth, setScrollBarWidth] = useState(0);
  const [margin, setMargin] = useState(0);

  const groupEl = useRef(null);

  function doSelect(e) {
    let li = e.target;
    while(li && li.tagName.toUpperCase() !== 'LI') {
      li = li.parentElement;
    }
    const lis = groupEl.current.querySelectorAll('li');
    let liH = 35;
    if(lis[0]) liH = lis[0].offsetHeight;
    const index = Array.prototype.indexOf.call(lis, li);
    const scrollTop = index * liH;
    groupEl.current.scrollTo({
      top: scrollTop,
      left: 0,
      behavior: 'smooth'
    });
  }
  function getValue() {
    const scrollTop = groupEl.current.scrollTop;
    const lis = groupEl.current.querySelectorAll('li');
    let liH = 35;
    if(lis[0]) liH = lis[0].offsetHeight;
    let index = Math.round(scrollTop / liH);
    index = index < 0 ? 0 : index;
    return lis[index] ? lis[index].dataset.value : '';
  }
  useImperativeHandle(ref, () => ({
    getValue,
  }));

  useEffect(() => {
    const w = getScrollBarWidth();
    if(w) setScrollBarWidth(w);
    const groupH = groupEl.current.offsetHeight;
    const li = groupEl.current.querySelector('li');
    let liH = 35;
    if(li) liH = li.offsetHeight;
    setMargin((groupH - liH) / 2);

    let timer = null;
    groupEl.current.addEventListener('scroll', (e) => {
      e.stopPropagation();
      clearTimeout(timer);
      timer = setTimeout(function () {
        if(props.onChange){
            props.onChange(getValue());
        }
        if(!isSupportCss('scroll-snap-type')) {
          // 计算停止的位置
          const scrollTop = groupEl.current.scrollTop;
          let index = Math.round(scrollTop / liH);
          index = index < 0 ? 0 : index;
          groupEl.current.scrollTop = index * liH;
        }
        console.log('scroll end');
      }, 100);
    }, { passive: false })
  }, []);

  const ulCss = css`
    margin-right:${0 - scrollBarWidth}px;
    padding-right: ${scrollBarWidth}px;
    margin-bottom:${margin}px;
    margin-top:${margin}px;
  `;

  const lis = (data||[{}]).map((it, $i) => {
    const value = optionValue ? it[optionValue] : it;
    const text = optionsRender ? optionsRender(it) : <span>{optionText ? it[optionText] : value}</span>;
    return <li data-value={value} key={value} onClick={doSelect}>{text}</li>;
  });
  return (
    <div className="_select-group" ref={groupEl}>
      <ul css={ulCss}>{lis}</ul>
    </div>
  );
}

export default forwardRef(SelectGroup);
