/*
* Author: LJH
* Date: 2021/12/27
* Description:
*/

import AsyncValidator from 'async-validator';

export function isSupportCss(attr) {
  return ['', 'webkit-', 'ms-', 'moz-','o-'].some(function(prefix) {
    var prefixed = (prefix + attr).replace(/-([a-z])/g, function(_, $1) { return $1.toUpperCase(); });
    return prefixed in document.documentElement.style;
  });
}

/**
 * 防抖
 * @param {延迟执行的函数} fn
 * @param {延迟时间} delay
 * @param {立即执行} immediate
 */
export function debounce(fn, delay, immediate = false) {
  let firstRun = true;
  let timer = null;
  let content, args;
  const clear = () => {
    clearTimeout(timer);
    timer = null;
  }
  const run = (_immediate) => {
    if(_immediate) fn.apply(content, args);
    else {
      timer = setTimeout(() => {
        fn.apply(content, args);
        clear();
      }, delay);
    }
  }

  const _fn = (...arg) => {
    content = this;
    args = arg;
    if(timer) {
      clear();
    }
    run(immediate && firstRun);
    if(firstRun) firstRun = false;
    return true;
  }
  _fn.abort = clear;
  return _fn;
}

export function createElement(type, { id, className, style, dataset, on }, children) {
  const div = document.createElement(type)
  if (id) div.setAttribute('id', id)
  if (className) div.setAttribute('class', className)
  if (style) div.setAttribute('style', style)
  if (on) {
    for (const event in on) div[`on${event}`] = on[event]
  }
  if (dataset) {
    for (const k in dataset) div.dataset[k] = dataset[k]
  }
  if (children && children.length) {
    children.map(it => {
      if (![null, undefined].includes(it)) {
        if (typeof it === 'string') div.innerHTML = it
        else div.appendChild(it)
      }
    })
  }
  return div
}

export function to(promise) {
  if (promise && promise.then) {
    return promise.then(data => [null, data]).catch(err => [err])
  }
  return [null, promise]
}

export function validate(rules, value, name = 'test', callback) {
  if (!rules || rules.length == 0) {
    if (callback) callback();
    return true;
  }
  const descriptor = {};
  descriptor[name] = rules;
  const validator = new AsyncValidator(descriptor);
  const model = {};

  model[name] = value;
  return new Promise((resolve, reject) => {
    validator.validate(
      model,
      { firstFields: true },
      (errors, invalidFields) => {
        if (callback) callback(errors, invalidFields);
        if (errors) {
          reject(errors);
        } else {
          resolve(invalidFields);
        }
      }
    );
  });
}
export const testMainlandPhone = (value) => {
  return /^(1\d{10})$/.test(value)
}
export const testHongKongPhone = (value) => {
  return /^(\d{8})$/.test(value)
}
export const testEmail = (value) => {
  return /^[A-Z0-9._%+-]{1,30}@[A-Z0-9.-]{1,50}\.[A-Z]{2,10}$/i.test(value)
}

export const testPassword = (value) => {
  let strength = 0;
  if (value.length >= 8 && value.length <= 16) {
    const reg = /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{8,16}$/;
    return reg.test(value);
  }
  if (strength >= 1) {
    return true;
  }
  return false;
};
