/*
* Author: LJH
* Date: 2021/12/29
* Description:
*/
export default [
  '9:30-11:30',
  '14:00-16:00',
  '16:00-18:00'
]
