/*
* Author: LJH
* Date: 2022/1/6
* Description:
*/

import React, { useState } from 'react';
import { useMount } from 'react-use';
import './index.scss';
import Judge from '../Judge';
import Transition from '../Transition';

const mutilLang = {
  cancel: {
    'en': 'Cancel',
    'zh-cn': '取消',
    'zh-tw': '取消'
  },
  confirm: {
    'en': 'Confirm',
    'zh-cn': '确认',
    'zh-tw': '確認'
  }
}

function Dialog(props) {
  const languageMatch = /^\/([^/]+)\//.exec(location.pathname);
  const confirmText = props.confirmBtnText ?? mutilLang.confirm[languageMatch?.[1] || 'en'];
  const cancelText = props.cancelBtnText ?? mutilLang.cancel[languageMatch?.[1] || 'en'];

  const [show, setShow] = useState(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  useMount(() => {
    setShow(props.show ?? true);
  });

  function _close() {
    props.close(false, 'close')
    setShow(false);
  }
  function _cancel() {
    props.close(false, 'cancel')
    setShow(false);
  }
  async function _confirm() {
    if(value) {
      const [err] = await to(_validate(props.inputConfig.rule, value));
      if(err) return false;
      props.callback(value, _close);
    }
    else {
      props.close(true, 'confirm');
      _close();
    }
  }
  function _input({target}) {
    const val = target.value
    setValue(val);
    const rule = (props.inputConfig.rule || []).filter(it => it.trigger == 'input');
    if(rule.length) _validate(rule, val);
  }
  function _blur({target}) {
    const rule = (props.inputConfig.rule || []).filter(it => it.trigger == 'blur');
    if(rule.length) _validate(rule, target.value);
  }
  function _change({target}) {
    const rule = (props.inputConfig.rule || []).filter(it => it.trigger == 'change');
    if(rule.length) _validate(rule, target.value);
  }
  async function _validate(rule, value) {
    /*
    const [err] = await to(validate(rule, value));
    if (err && err.length) {
        setError(err[0].message);
        // 无奈的uncatch
        // return Promise.reject(err);
        return false;
    }
    else if(error) {
        setError('')
        return true;
    }
    */
  }
  return (
    <Transition name="opacity" show={show} delayMount={true}>
      <div className={`_dialog-wrap ${props.className}`}>
        <div className="mask"></div>
        <div className="_dialog">
          <Judge>
            <span if={props.showClose} className="iconfont icon-close close" onClick={_close}></span>
          </Judge>
          <Judge>
            <header if={!!props.title} className="_dialog-header" dangerouslySetInnerHTML={{__html: props.title}}></header>
          </Judge>
          <main className="_dialog-main font-Regular">
            {/* todo: 包含输入框 */}
            <Judge>
              <div if={props.input}>
                <label dangerouslySetInnerHTML={{__html: props.label}}></label>
                <input ref="input" className="input" {...props.inputConfig} onInput={_input} onBlur={_blur} onChange={_change} />
                <p className="error" dangerouslySetInnerHTML={{__html: error}}></p>
              </div>
            </Judge>
            {props.children ? props.children : props.content}
          </main>
          <footer className="_dialog-footer">
            <Judge>
              <button onClick={_cancel} className='btn btn-primary-o' if={cancelText}>{cancelText}</button>
            </Judge>
            <button disabled={props.input && (!value || error)} onClick={_confirm} className='btn btn-primary-f' >{confirmText}</button>
          </footer>
        </div>
      </div>
    </Transition>
  )
}

export default Dialog
