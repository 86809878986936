/*
* Author: LJH
* Date: 2021/8/25
* Description: http 异常状态码
*/
export default {
  /*400: '访问参数错误，请重试。',
  401: '授权失败，请重新登录。',
  403: '请求资源权限不足。',
  404: '请求资源不存在。',
  500: '服务器未知错误，请重试。',
  503: '服务不可用，请重试。',
  504: '服务请求超时，请重试。',*/
  400: 'Access parameter error, please try again',
  401: 'Authorization failed, please log in again',
  403: 'Insufficient permissions on requested resource',
  404: 'The requested resource does not exist',
  500: 'Unknown server error, please try again',
  503: 'Service is unavailable, please try again',
  504: 'Service request timed out, please try again',
}
